import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from "@mui/material";
import { useRouter } from "next/router";
import { FC } from "react";
import AmplitudeHelper, { EventDataFormDialogRestartOrigin } from "../../utils/AmplitudeHelper";
import { useTypedSelector } from "../../utils/use-typed-selector";


interface HomeSectionStartShoppingProps {
    shop: any,
    button: any,
    slogan: any,
    subCategoryRedirectionPath: string,
    openEventDialog: () => void
}
export const HomeSectionStartShopping: FC<HomeSectionStartShoppingProps> = (props) => {

    const router = useRouter();
    const isCurrentlyShopping = useTypedSelector((state) => state.eventReducer.currentEvent !== null);

    return (
        <div className="home-start-shopping-container">
            <div className='home-slogan'>
                <h4 className='sedeo-main-color-text'>Sedeo :</h4><span>{props.slogan}</span>
            </div>
            <div className="button-container">
                <Button className="sedeo-button-rounded home-button"
                    onClick={() => {
                        if(isCurrentlyShopping){
                            AmplitudeHelper.trackEventHomeNewOrderClick(EventDataFormDialogRestartOrigin.REVIEWS);
                            props.openEventDialog();
                        }else{
                            AmplitudeHelper.trackHomeCTA("Button-REVIEWS")
                            router.push({pathname: props.subCategoryRedirectionPath});
                        }
                    }}>
                    <VisibilityIcon/>{props.button}
                </Button>
            </div>
        </div>
    )
}