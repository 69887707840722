import { FC } from "react";


interface HomeSectionEcologyProps {
    ecology:any
}
export const HomeSectionEcology: FC<HomeSectionEcologyProps> = (props) => {

    return (
        <div className="home-ecology">
            <div className="home-ecology-container">

                <div className="title">
                    <p className="sedeo-main-color-text">{props.ecology.Title.pt1}</p>
                    <p className="we-saved">{props.ecology.Title.pt2}</p>
                </div>

                <div className="ecology-stat">
                    <p className="title sedeo-main-color-text">{props.ecology.Stats.Number}</p>
                    <p>{props.ecology.Stats.Description}</p>
                </div>

                <div className="additional-info">
                    <span>{props.ecology.Stats.Additional.pt1}</span>
                    <span>{props.ecology.Stats.Additional.pt2}</span>
                    <span>{props.ecology.Stats.Additional.pt3}</span>
                </div>

            </div>
        </div>
    )
}