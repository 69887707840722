import { FC } from "react";
import { ReactSVG } from "react-svg";

const ReviewBox = (props: {review: string, name: string, work: string, post: string}) => {

    return (
        <div className="review-box hoverable">

            <h3 className="comment">
                {props.review}
            </h3>

            <div className="reviewer">
                <div className="quote">
                    <ReactSVG src={'/assets/quotes.svg'}/>
                </div>

                <div className="reviewer-info">
                    <h4>{props.name} - {props.work}</h4>
                    <p>{props.post}</p>
                </div>
            </div>

        </div>
    )
}


interface HomeSectionReviewsProps {
    reviews: any,
    button: any,
    subCategoryRedirectionPath: string
    openEventDialog: () => void
}
export const HomeSectionReviews: FC<HomeSectionReviewsProps> = (props) => {

    return (
        <div className="home-reviews-container">

            <h4 className="sedeo-main-color-text">{props.reviews.Title}</h4>

            <p>{props.reviews.Description}</p>

            <div className="reviews">

                <div className="left">
                    <ReviewBox review={props.reviews.Rev1.text}
                        name={props.reviews.Rev1.name}
                        work={props.reviews.Rev1.work}
                        post={props.reviews.Rev1.post}/>

                    <ReviewBox review={props.reviews.Rev2.text}
                        name={props.reviews.Rev2.name}
                        work={props.reviews.Rev2.work}
                        post={props.reviews.Rev2.post}/>
                </div>

                <div className="right">
                    <ReviewBox review={props.reviews.Rev3.text}
                        name={props.reviews.Rev3.name}
                        work={props.reviews.Rev3.work}
                        post={props.reviews.Rev3.post}/>

                    <ReviewBox review={props.reviews.Rev4.text}
                        name={props.reviews.Rev4.name}
                        work={props.reviews.Rev4.work}
                        post={props.reviews.Rev4.post}/>
                </div>

            </div>

        </div>
    )
}