import { FC } from "react";
import CountUp from "react-countup";

const Stat = (props: {num: number, title: string}) => {
    return  (
        <div className="stat">
            <p className="title">
                <CountUp
                    duration={3}
                    end={(props.num)}
                    enableScrollSpy/>
                +
            </p>
            <p>{props.title}</p>
        </div>
    )
}

interface HomeSectionStatsProps {
    stats: any
}
export const HomeSectionStats: FC<HomeSectionStatsProps> = (props) => {

    return (
        <div className="home-stats-section">
            <div className="stats-wrapper">
                <div className="left">
                    <div>
                        <h2>Sedeo</h2>
                        <p>en quelques chiffres</p>
                    </div>
                </div>
                <div className="right">
                    <Stat num={3000} title={props.stats.S1}/>
                    <Stat num={1200} title={props.stats.S2}/>
                    <Stat num={50} title={props.stats.S3}/>
                </div>
            </div>
        </div>
    )
}