import { Grid } from "@mui/material";
import { FC } from "react";
import { ReactSVG } from "react-svg";
import { MarketCategoryType } from "../../typing/types";
import AmplitudeHelper, { MarketCategoryClickOrigin } from "../../utils/AmplitudeHelper";
import IconHelper from "../../utils/IconHelper";
import { HomeCategoryItem } from "../catalog/category/HomeCategoryItem";


interface HomeSectionCatalogProps {
    marketCategories: MarketCategoryType[]
    section2: any
}
export const HomeSectionCatalog: FC<HomeSectionCatalogProps> = (props) => {

    const { marketCategories, section2 } = props;

    return (
        <div className="home-section-catalog main-borders-margin">

            <div className="home-catalog-title">
                <p>Adoptez une attitude <span>responsable</span></p>
                <p>et devenez <span>loueur</span> de mobilier !</p>
            </div>

            {marketCategories?.length > 0 &&
                <Grid container className="category-grid">

                    {
                        marketCategories.map((marketCategory, index) => {
                            return <HomeCategoryItem 
                                key={index}
                                clickable={true}
                                onLinkClicked={() => {
                                    if(marketCategory){
                                        AmplitudeHelper.trackMarketCategoryClick(marketCategory.slug, MarketCategoryClickOrigin.HOME)
                                        AmplitudeHelper.trackHomeCTA(`MarketCategory-${marketCategory.slug}`)
                                    }
                                }}
                                marketCategory={marketCategory}
                                imageSrc={'assets/categories/'+marketCategory.slug+'.webp'}
                                imageAlt={marketCategory.slug}
                                title={marketCategory.content.name}
                                subtitle={marketCategory.content.title}
                                description={marketCategory.content.shortDescription}/>
                        })
                    }
                </Grid>
            }
            
            <div className="catalog-more-info">
                <ReactSVG className="question-mark" src={IconHelper.getMarketCategoryIconPath("question-sign")}/>
                <div className="info-text">
                    <p>{section2.Description?.pt1}
                    <a className="email-address" href={`mailto:contact@sedeo.fr`} rel="nofollow">
                        contact@sedeo.fr
                    </a>
                    {section2.Description?.pt2}</p>
                </div>
            </div>

        </div>
    )
}