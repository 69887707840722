import { Button } from "@mui/material";
import { useRouter } from "next/router";
import { FC } from "react";
import AmplitudeHelper, { EventDataFormDialogRestartOrigin } from "../../utils/AmplitudeHelper";
import { useTypedSelector } from "../../utils/use-typed-selector";
import BasicCarousel from "../common/BasicCarousel";
import Lottie from "lottie-react";
import timeAnimation from "../../../public/assets/animations/time.json";
import { HomeSectionPartners } from "./HomeSectionPartners";
import { LazyImage } from "../common/LazyImage";

interface HomeMainSectionProps {
    mainSection: any;
    button: string | null;
    subCategoryRedirectionPath: string;
    openEventDialog: () => void;
}

export const HomeMainSection: FC<HomeMainSectionProps> = (props) => {

    const {mainSection, button, subCategoryRedirectionPath, openEventDialog} = props
    const router = useRouter();
    const isCurrentlyShopping = useTypedSelector((state) => state.eventReducer.currentEvent !== null);
    const mainSectionImages: {name: string, url: string}[] = [
        {name: "Sedeo mariage", url: "/assets/home/main-section/5.webp"},
        {name: "Sedeo art de la table", url: "/assets/home/main-section/16.webp"},
        {name: "Sedeo meuble extérieur", url: "/assets/home/main-section/19.webp"},
        {name: "Sedeo tentes", url: "/assets/home/main-section/24.webp"},
        {name: "Sedeo décor", url: "/assets/home/main-section/41.webp"},
        {name: "Sedeo lumière", url: "/assets/home/main-section/43.webp"},
    ]

    return (
        <>
            <div className="home-section-1">
                <div className="main-grid">
                    
                    <div className="main-carousel">
                        <div className="carousel-overlay"></div>
                        <BasicCarousel 
                            autoplay={true}
                            loop={true}
                            showDots={true}
                            slidesPerview={1}
                            spacing={0}
                            >
                            {mainSectionImages.map((image, index) => (
                                <div key={index} className='keen-slider__slide'>
                                    <LazyImage 
                                        // don't lazy load the first item to avoid affecting the First Contentful Paint (FCP) and Largest Contentful Paint (LCP)
                                        lazy={index > 0}
                                        src={image.url}
                                        alt={image.name+"-"+index}
                                        width={1900}
                                        height={600}
                                        useLoader={false}
                                    />
                                </div>
                            ))}
                        </BasicCarousel>
                    </div>

                    <div className="main-section-content">

                        <h1 className="sec1-title">{mainSection.Title}</h1>

                        <h2 className="sec1-description">
                            {mainSection.Description.pt1 + ' '}
                            <span>
                                {mainSection.Description.pt2}
                                <div className="sedeo-rectangle"></div>
                            </span>
                        </h2>

                        <Button variant="contained"
                            className="sedeo-button-rounded home-button"
                            onClick={() => {
                                if(isCurrentlyShopping){
                                    AmplitudeHelper.trackEventHomeNewOrderClick(EventDataFormDialogRestartOrigin.TOP);
                                    openEventDialog();
                                }else{
                                    AmplitudeHelper.trackHomeCTA("Button-TOP")
                                    router.push({pathname: subCategoryRedirectionPath});
                                }
                            }}>
                            <Lottie className="time" animationData={timeAnimation} style={{height: 80}} loop={true} />
                            {button}
                        </Button>

                    </div>

                </div>
                <HomeSectionPartners/>
            </div>
        </>
    )
}