import 'i18n';
import { t } from "i18next";
import Head from 'next/head';
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SEDEO_PALETTE } from '../src/theme/themeColors';
import { MarketCategoryType } from "../src/typing/types";
import { CategoriesTopBar } from "../src/ui/common/CategoriesTopBar";
import { EventDataFormDialog } from "../src/ui/eventDataForm/EventDataFormDialog";
import { Header } from "../src/ui/header/header";
import { HomeMainSection } from '../src/ui/home/HomeMainSection';
import { HomeSectionBlog } from '../src/ui/home/HomeSectionBlog';
import { HomeSectionCatalog } from "../src/ui/home/HomeSectionCatalog";
import { HomeSectionEcology } from "../src/ui/home/HomeSectionEcology";
import { HomeSectionReviews } from "../src/ui/home/HomeSectionReviews";
import { HomeSectionStartShopping } from "../src/ui/home/HomeSectionStartShopping";
import { HomeSectionStats } from "../src/ui/home/HomeSectionStats";
import { HomeSectionValues } from "../src/ui/home/HomeSectionValues";
import AmplitudeHelper from "../src/utils/AmplitudeHelper";
import MarketCategoryAPIHelper from "../src/utils/api/cms/MarketCategoryAPIHelper";
import BlogApiHelper, { BlogPost } from '../src/utils/api/strapi/BlogApiHelper';
import RoutesHelper, { STATUS } from "../src/utils/consts";
import { useTypedSelector } from "../src/utils/use-typed-selector";
import BugsnagHelper from "../src/utils/BugsnagHelper";


export async function getStaticProps() {

    let marketCategories: MarketCategoryType[] = []
    let blogPosts: BlogPost[] = []
    try{
        marketCategories = await MarketCategoryAPIHelper.getAllMarketCategories()
        blogPosts = await BlogApiHelper.getAllShortBlogPost()
    }catch (err) {
        console.error(err)
        BugsnagHelper.captureMessage("/home getStaticPaths error: "+JSON.stringify(err))
    }

    return {
        //pre-populate props from the server side
        props: {
            ssg_marketCategories: marketCategories,
            ssg_blogPosts: blogPosts
        },
    }
}

interface CategoriesLoadingState {
    status: STATUS.LOADING;
}

interface CategoriesDataState {
    status: STATUS.DATA;
    marketCategories: MarketCategoryType[];
}

type CategoriesState = CategoriesLoadingState | CategoriesDataState

const Home = (props: {ssg_marketCategories: MarketCategoryType[], ssg_blogPosts: BlogPost[]}) => {

    const router = useRouter();
    const { ssg_marketCategories, ssg_blogPosts } = props;
    const [openEventDataFormDialog, setOpenEventDataFormDialog] = useState(false);
    const [mainButtonText, setMainButtonText] = useState(t("BUTTONS.GetMyInvoice"));
    const isCurrentlyShopping = useTypedSelector((state) => state.eventReducer.currentEvent !== null);
    const SPToken = useTypedSelector((state) => state.eventReducer.currentEvent?.SPToken ?? null);
    const [state, setState] = useState<CategoriesState>({
        status: STATUS.LOADING
    });
    // Check if we have a subcategory. If we have one return the first one otherwise redirection leads to the home page
    const subCategoryRedirectionPath = ssg_marketCategories.length > 0 ?
        ssg_marketCategories[0].content.marketSubCategoriesSlug.length > 0 ?
            RoutesHelper.getSubCategoryRouteByPathName(ssg_marketCategories[0].slug, ssg_marketCategories[0].content.marketSubCategoriesSlug[0])
            : ''
        : ''

    useEffect(() => {
        setMainButtonText(isCurrentlyShopping ? t("BUTTONS.NewOrder") : t("BUTTONS.ViewCatalog"))
    }, [isCurrentlyShopping])

    useEffect(() => {
        AmplitudeHelper.trackUserIsInHome();
    }, [])

    useEffect(() => {
        if(SPToken) {
            setState({ status: STATUS.LOADING })
            MarketCategoryAPIHelper.getMarketCategoriesForSpatialToken(SPToken, false).then(availableMarketCategories => {
                setState(() => ({
                    status: STATUS.DATA,
                    marketCategories: availableMarketCategories
                }))
            }).catch(err => {
                setState(() => ({
                    status: STATUS.DATA,
                    marketCategories: ssg_marketCategories
                }))
                BugsnagHelper.captureMessage(`/home SPToken fetch data error for ssg_marketCategories : ${JSON.stringify(err)}`)
            })
        }
        else {
            setState(() => ({
                status: STATUS.DATA,
                marketCategories: ssg_marketCategories
            }))
        }
    }, [SPToken, ssg_marketCategories])

    return (
        <>
            <Head>
                <title>{t('HOME.TabName')}</title>
                <meta name="description" content="L'expérience de location de matériel évènementiel simplifiée"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={"Sedeo: Location de matériel évènementiel en ligne"} />
                <meta property="og:description" content={"L'expérience de location de matériel évènementiel simplifiée"}/>
                <meta property="og:image" content={`${process.env.NEXT_PUBLIC_BASE_URL}/sedeo_default_og_image.png`} />
                <meta property="og:url" content={`${process.env.NEXT_PUBLIC_BASE_URL}`} />
                <link rel="icon" href="/favicon.ico"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <meta name="msapplication-TileColor" content={SEDEO_PALETTE.title}/>
                <meta name="theme-color" content={SEDEO_PALETTE.white}/>
                <link rel="canonical" href={`${process.env.NEXT_PUBLIC_BASE_URL}`}/>
                <script
                    async
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "LocalBusiness",
                            "address": {"@type": "PostalAddress", "addressLocality": "Paris", "addressRegion": "FR", "postalCode": "75012", "streetAddress": "68 avenue du général Michel Bizot"},
                            "geo": {"@type": "GeoCoordinates", "latitude": 48.836022939362586, "longitude": 2.4026177},
                            "aggregateRating": {"@type": "AggregateRating", "ratingValue": "4.9", "reviewCount": "77"},
                            "name": "Sedeo", "openingHours": ["Mo-Fr 09:00-18:00"],
                            "telephone": "0756963617",
                            "url": "https://www.sedeo.fr/"})
                    }}
                />
            </Head>

            <div className="home-sticky-header">
                <Header
                    isUserInHome={true}
                    isUserInMarket={false}
                    isUserInOrders={false}
                    isUserInSearch={false}
                />
            </div>

            <div className="home-content">
                
                <CategoriesTopBar marketCategories={state.status === STATUS.LOADING ? null : state.marketCategories} isUserInHome/>
                <HomeMainSection mainSection={t("HOME.Main")} button={t("BUTTONS.GetMyInvoice")} subCategoryRedirectionPath={subCategoryRedirectionPath} openEventDialog={() => setOpenEventDataFormDialog(true)}/>
                <HomeSectionValues section2={t("HOME.Sec3")} button={mainButtonText} subCategoryRedirectionPath={subCategoryRedirectionPath} openEventDialog={() => setOpenEventDataFormDialog(true)}/>
                <HomeSectionCatalog marketCategories={ssg_marketCategories} section2={t("HOME.Sec2")}/>
                <HomeSectionBlog blogPosts={ssg_blogPosts} title={t('HOME.Blog.Title')} description={t('HOME.Blog.Description')}/>
                <HomeSectionEcology ecology={t("HOME.Ecology")}/>
                <HomeSectionStats stats={t("HOME.Stats")}/>
                <HomeSectionReviews reviews={t("HOME.Reviews")} button={mainButtonText} subCategoryRedirectionPath={subCategoryRedirectionPath} openEventDialog={() => setOpenEventDataFormDialog(true)}/>
                <HomeSectionStartShopping shop={t("HOME.Shop")} button={mainButtonText} slogan={t("GLOBALS.Slogan")} subCategoryRedirectionPath={subCategoryRedirectionPath} openEventDialog={() => setOpenEventDataFormDialog(true)}/>

            </div>

            <EventDataFormDialog
                onCancel={() => setOpenEventDataFormDialog(false)}
                onSuccess={(spatialToken: string, defaultMarketRedirectionUrl: string) => {
                    setOpenEventDataFormDialog(false)
                    // Redirect to the default catalog page
                    router.push(defaultMarketRedirectionUrl)
                }}
                open={openEventDataFormDialog}
            />

        </>
    )
}

export default Home
