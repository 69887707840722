import { FC } from "react";
import { CategoryItem } from "../catalog/category/CategoryItem";


interface HomeSectionValuesProps {
    section2: any,
    button: any,
    subCategoryRedirectionPath: string,
    openEventDialog: () => void
}
export const HomeSectionValues: FC<HomeSectionValuesProps> = (props) => {

    return (
        <div className="home-values-section">

            <div className="values">

                    <CategoryItem 
                        imagePath='/assets/simplicite.svg'
                        imageType="png"
                        imageAlt={'simplicité'}
                        title={props.section2.Q1.Title}
                        subtitle=""
                        shortDescription={props.section2.Q1.Description}/>

                    <CategoryItem 
                        imagePath='/assets/fiabilite.svg'
                        imageType="png"
                        imageAlt={'fiabilité'}
                        title={props.section2.Q2.Title}
                        subtitle=""
                        shortDescription={props.section2.Q2.Description}/>
                
                    <CategoryItem 
                        imagePath='/assets/variete.svg'
                        imageType="png"
                        imageAlt={'variété'}
                        title={props.section2.Q3.Title}
                        subtitle=""
                        shortDescription={props.section2.Q3.Description}/>
            </div>
            
        </div>
    )
}